<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            type="user"
            label="근로자"
            name="userId"
            v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 조사기간 -->
          <c-datepicker
            :range="true"
            type="year"
            label="조사년도"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            stepperGrpCd="HEA_BRAIN_CARDIOVASCULAR_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="heaBrainCardiovascularStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.heaBrainCardiovascularStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            label="LBLWRITEDEPT"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <c-table
      title="뇌심혈관계 종합조사 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" /> 
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'brainCardiovascular',
  data() {
    return {
      searchParam: {
        period: [],
        userId: '',
        heaBrainCardiovascularStepCd: null,
        deptCd: '',
      },
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '근로자',
            align: 'center',
            sortable: false,
            style: 'width:120px',
            type: 'link'
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'age',
            field: 'age',
            label: '나이(조사시점)',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'surveyDate',
            field: 'surveyDate',
            label: '조사일',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'heaBrainCardiovascularStepName',
            field: 'heaBrainCardiovascularStepName',
            label: '진행상태',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.bcs.survey.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      this.openPop(row)
    },
    openPop(row) {
      this.popupOptions.title = '뇌심혈관계';
      this.popupOptions.param = {
        heaBrainCardiovascularSurveyId: row ? row.heaBrainCardiovascularSurveyId : '',
      };
      this.popupOptions.target = () => import(`${'./brainCardiovascularDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    }
  }
};
</script>
